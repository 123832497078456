.p-x-landing {
  padding-inline: 6.25rem;
}

.p-y-landing {
  padding-block: 7rem;
}

.landing-layout-container {
  min-height: 100vh;
}

.landing-nav > .login-btn {
  padding-inline: 64px;
}

.title-underline {
  width: 236px;
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  margin: auto;
}

.active-notification-button-underline {
  border-bottom: 2px solid var(--primary-color) !important;
  border-radius: 0 !important;
}

.inactive-notification-button-underline {
  border-bottom: 2px solid var(--muted) !important;
  border-radius: 0 !important;
}

.benefits .material-symbols-rounded {
  font-size: 2rem;
  margin-right: 20px;
}

.benefit-icons {
  margin-top: 40px;
}

.benefit-icon .material-symbols-rounded {
  font-size: 3rem;
}

.benefit-icon {
  cursor: default;
}

.tooltip {
  max-width: 274px;
  padding: 10px 12px;
  border-radius: 10px;
}

.tracking-img {
  margin: auto;
}

.tracking-input .MuiInputBase-root {
  border-radius: 5px 0 0 5px;
}

.tracking-input .MuiButtonBase-root {
  border-radius: 0 5px 5px 0;
  height: 40px;
}

.tracking-input .MuiButtonBase-root .material-symbols-rounded {
  font-size: 1.8rem;
}

.landing-menu-icon {
  display: none;
}

.landing-menu.MuiPaper-root {
  left: 0 !important;
  max-width: unset;
  box-sizing: border-box;
  border-radius: 0px;
}

.tracking-card {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.plan-card-container {
  max-width: 287px;
}

.footer-icon:hover {
  background-color: var(--primary-color-dark);
}

.tracking-error {
  margin-top: -1em;
  margin-bottom: 3em;
}

@media screen and (max-width: 1500px) {
  .p-x-landing {
    padding-inline: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  .p-x-landing {
    padding-inline: 1rem;
  }
}

@media screen and (max-width: 870px) {
  .p-y-landing {
    padding-block: 1.5rem;
  }

  .landing-banner {
    content:url('../images/landing/landing_banner_mobile.png');
  }

  .landing-menu-icon {
    display: inline-flex;
  }

  .landing-nav {
    display: none;
  }

  .title-underline {
    width: 130px;
    border: 1px solid var(--primary-color);
  }

  .benefits .material-symbols-rounded {
    font-size: 1rem;
    margin-right: 5px;
  }

  .benefit-icon .material-symbols-rounded {
    font-size: 2rem;
  }

  .benefit-icons {
    margin-top: 20px;
  }

  .tracking-img {
    display: none;
  }

  .plan-card-container {
    max-width: 206px;
  }
}
