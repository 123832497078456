.card-plans {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media only screen and (min-width: 768px) {
  .card-plans {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2.5rem !important;
    padding-top: 1.875rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
