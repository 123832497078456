.navbar-no-shadow {
  box-shadow: none;
}

.icon-m-r {
  margin-right: 1.25rem;
}

.navbar__options__menu {
  padding: 20px 10px;
  text-align: center;
  min-width: 250px;
}

.navbar__menu__avatar {
  margin: 0 auto;
  width: 4.375rem;
  height: 4.375rem;
}

.navbar__options__menu hr {
  margin-bottom: 0.5rem;
}

.navbar__options__menu * > span {
  margin-right: 23px;
}

.menu-skeleton-size {
  margin: 0 1.25rem 0 -0.75rem;
}

@media only screen and (min-width: 768px) {
  .toolbar {
    padding-right: 3.563rem;
  }

  .navbar__options__menu {
    transform: translateX(-45px) !important;
  }
}
