.metrics-container {
  padding: 1.25rem 2.5rem 0 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.summaryOlsContainer:nth-child(odd) {
  border-right: 1px solid #dedede;
}

.custom-border-left {
  border-left: 1px solid #dedede;
}

.badge-orders-metrics {
  width: 18px;
  height: 8px;
  border-radius: 10px;
}

.badge-orders-metrics.badge-blue {
  background-color: #0064C2;
}

.badge-orders-metrics.badge-yellow {
  background-color: #E3D027;
}
