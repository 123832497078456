/* Buttons */
.MuiButton-root {
  text-transform: none;
  box-shadow: none;
}

/* Tables */
.MuiTableCell-root {
  padding: 0.688rem 1rem;
}

.MuiCheckbox-colorPrimary {
  color: #c5c7cf;
}

/* Modals */
.MuiDialog-paper {
  border-radius: 0.625rem;
}

/* Tabs */
.MuiTabPanel-root {
  padding: 0px;
}

.MuiButtonBase-root.MuiTab-root {
  text-transform: none;
  font-size: 1rem;
  color: var(--text-dark);
}

/* alerts */
.MuiAlert-standardError {
  background-color: rgb(217, 0, 1, 0.2);
  color: var(--text-error);
}

.MuiAlert-standardWarning {
  background-color: rgb(227, 208, 39, 0.2);
}

.MuiAlert-standardInfo {
  background-color: #ffdfcd;
  color: #f96e20;
}

.MuiAlert-standardInfo a {
  color: #e32728;
}

.MuiAlert-standardSuccess {
  background-color: #d1e4ff;
  color: var(--success-color);
}

/* Progress bar */
.MuiLinearProgress-root {
  height: 5px;
  background-color: var(--bg-white);
  border-radius: 70px;
}

.MuiLinearProgress-bar1 {
  border-radius: 70px;
}

.MuiStepConnector-line {
  min-height: 100px;
  border-left-width: 2px;
}

.MuiStepLabel-root {
  padding: 0;
}

.MuiStepLabel-label {
  font-size: 20px;
  font-weight: 400;
  color: var(--text-dark);
}

.material-symbols-rounded {
  font-size: 1.125rem;
}

/* Cards */
.MuiCard-root,
.MuiTooltip-tooltip {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.bg-light {
  background-color: var(--bg-light);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-error {
  background-color: var(--text-error);
}

.text-muted {
  color: var(--text-muted);
}

.text-success {
  color: var(--success-color);
}

.bg-input {
  background-color: var(--bg-white) !important;
}

.bg-secondary {
  background-color: var(--bg-secondary);
}

input:disabled {
  cursor: not-allowed;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.pointer {
  cursor: pointer;
}

.f-s-10 {
  font-size: 0.62rem;
}

.f-s-12 {
  font-size: 0.75rem;
}

.f-s-14 {
  font-size: 0.875rem;
}

.f-s-16 {
  font-size: 1rem;
}

.f-s-18 {
  font-size: 1.125rem;
}

.f-s-20 {
  font-size: 1.25rem;
}

.f-s-24 {
  font-size: 1.5rem;
}

.f-s-32 {
  font-size: 2rem;
}

.f-s-36 {
  font-size: 2.25rem;
}

.f-s-42 {
  font-size: 2.625rem;
}

.f-s-60 {
  font-size: 3.75rem;
}

.p-20 {
  padding: 1.25rem;
}

.p-l-20 {
  padding-left: 1.25rem;
}

.p-l-30 {
  padding-left: 2rem;
}

.p-y-3 {
  padding-top: 0.188rem;
  padding-bottom: 0.188rem;
}

.p-y-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-y-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.p-b-40 {
  padding-bottom: 2.5rem;
}

.notifications-menu-header {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.188rem;
}

.notifications-menu-header-divider {
  margin-left: 1.188rem;
  margin-right: 1.188rem;
}

.p-x-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.p-x-card {
  padding-left: 54px;
  padding-right: 54px;
}

.p-card {
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
}

.p-modal-message {
  padding: 3rem 5rem;
}

.p-modal-form {
  padding: 2rem;
}

.p-menu {
  padding: 1rem 1.5rem;
}

.m-r-10 {
  margin-right: 0.625rem;
}

.m-r-20 {
  margin-right: 1.25rem;
}

.datePickerHeight {
  height: 37.1333px;
}

.datePickerHeight div {
  height: 100%;
}

.m-b-10 {
  margin-bottom: 0.625rem;
}

.m-b-20 {
  margin-bottom: 1.25rem;
}

.webhookContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.webhookForm {
  height: 18em;
}

.webhookSelect {
  margin-bottom: 1em;
}

.m-b-30 {
  margin-bottom: 1.875rem;
}

.m-b-40 {
  margin-bottom: 2.5rem;
}

.m-t-2 {
  margin-top: 0.125rem;
}

.m-t-3 {
  margin-top: 0.188rem;
}

.m-b-5 {
  margin-bottom: 0.313rem;
}

.m-t-5 {
  margin-top: 0.313rem !important;
}

.m-x-5 {
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}

.m-x-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.m-y-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.m-l-5 {
  margin-left: 0.313rem;
}

.m-l-10 {
  margin-left: 0.625rem;
}

.m-l-20 {
  margin-left: 1.25rem;
}

.m-l-30 {
  margin-left: 1.875rem;
}

.m-t-10 {
  margin-top: 0.625rem;
}

.m-t-20 {
  margin-top: 1.25rem;
}

.m-t-30 {
  margin-top: 1.875rem;
}

.m-t-40 {
  margin-top: 2.5rem;
}

.m-t-100 {
  margin-top: 6.25rem;
}

.m-b-20 {
  margin-bottom: 1.25rem;
}

.m-t-50 {
  margin-top: 3.125rem;
}

.m-b-50 {
  margin-bottom: 3.125rem;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.h-50 {
  height: 50vh;
}

.h-100 {
  height: 100%;
}

.br-5 {
  border-radius: 0.313rem;
}

.br-10 {
  border-radius: 0.625rem;
}

.br-20 {
  border-radius: 1.25rem;
}

.w-630 {
  width: 45.5625rem;
}

.text-center {
  text-align: center;
}

.text-dark {
  color: var(--text-dark);
}

.text-white {
  color: var(--bg-white);
}

.text-bold {
  font-weight: 700;
}

.color-primary {
  color: var(--primary-color);
}

.color-error {
  color: var(--text-error);
}

.sidebar-icon-margin {
  margin-left: 0.219rem;
}

.menu-item-hover:hover {
  background-color: #f5f5f5;
}

.space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.flex-1-3 {
  flex: 1.3;
}

.flex-7-5 {
  flex: 7.5;
}

.direction-column {
  flex-direction: column;
}

.space-evenly {
  justify-content: space-evenly;
}

.icon-shadow {
  border-radius: 147px;
  box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: -1px 4px 5px 0px rgba(0, 0, 0, 0.11);
}

.notifications-badge {
  margin: -0.125rem;
}

.notification-badge-component-props {
  padding: 0.175rem;
}

.list-eight-notifications {
  margin-top: 0.563rem;
  max-height: 41.875rem;
}

.w-logo {
  width: 100%;
}

.b-right {
  border-right: 1px solid var(--muted);
}

.alertIconsuccess div svg {
  color: var(--success-color);
}

.date-picker-svg svg {
  color: var(--primary-color);
}

.MuiSelect-select.MuiSelect-standard:focus {
  background: none;
}

.bg-contact-section {
  background-color: #efefef;
}

.loader-size-lg {
  width: 100vw;
  height: 100vh;
}

.loader-size-md {
  width: 50vw;
  height: 50vh;
}

.search-min-width {
  min-width: 13.938rem;
}

.success-gif-size {
  width: 12.5rem;
  height: 12.5rem;
}

.back-button {
  background: #fdfdfd;

  &:hover {
    background: #f5f5f5;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 870px) {
  .m-t-40 {
    margin-top: 25px;
  }
}

@media (max-width: 767px) {
  .p-x-card {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .f-s-md-12 {
    font-size: 0.75rem;
  }

  .f-s-md-14 {
    font-size: 0.875rem;
  }

  .f-s-md-16 {
    font-size: 1rem;
  }

  .f-s-md-20 {
    font-size: 1.25rem;
  }

  .f-s-md-24 {
    font-size: 1.5rem;
  }

  .f-s-md-32 {
    font-size: 2rem;
  }

  .f-s-md-36 {
    font-size: 2.25rem;
  }

  .f-s-md-60 {
    font-size: 3.75rem;
  }
}

#panel1d-header {
  flex-direction: row-reverse;
}

#operators-accordion-expanded-rows {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  background-color: rgba(233, 232, 232, 1);
  padding-left: 86px;
}

#operators-accordion-card {
  padding-bottom: 3rem;
}

#operators-accordion-header {
  height: 50px;
  display: flex;
  align-items: center;
}

.opetators-accordion-border-bottom {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

#operators-accordion-main-row {
  background-color: transparent;
}

#operators-accordion-main-row:before {
  background-color: transparent !important;
}