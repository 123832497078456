.sidebar-container {
  height: 100vh;
  background-color: var(--bg-secondary);
}

.active > .MuiListItemButton-root * {
  color: #e32728;
}

#sidebar-text > .MuiTypography-root {
  font-size: 0.875rem;
}

.MuiListItemIcon-root {
  min-width: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .sidebar-list {
    margin-top: 4rem;
  }
}
