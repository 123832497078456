.close-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.upload-image-overlay::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  cursor: pointer;
  border-radius: 5px;
  z-index: 1;
}

.userErrorsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userErrors {
  margin-top: -1em;
  margin-bottom: 1em;
}