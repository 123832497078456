.fileUpload {
  display: flex;
  flex-direction: column;
}

.fileUpload label {
  margin-top: 0.5rem;
  font-size: 15px;
}

.fileUpload span {
  width: 100%;
  margin-top: 0.5rem;
  font-size: 12px;
}
