.notification-unread {
    background-color: #EDEDED !important;
    height: 114px;
}

#notification-table .MuiTableCell-root {
    padding: 0.688rem 2rem;
}

.w-90 {
    width: 90%;
}

.selected-notifications-content {
    background-color: #F0EFEF;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 0px 4px 4px 0px #0000001A;
    position: sticky;
    top: 60px;
    margin-top: -25px;
    margin-bottom: 75px;
    z-index: 1;
}

.h-58 {
    height: 58px;
}

.selected-notifications-container {
    position: sticky;
    top: 60px;
    margin-bottom: 25px;
    margin-top: -25px;
}

.notification-list-row {
    border-bottom: 1px solid #DADADA;
    padding: 9px 0;
}

.notification-message {
    width: 400px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.notif-cont-box-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

/* .notifications-button {
    border-radius: 0;
    width: 113px;
} */

/* .notifications-button-active {
    border-bottom: 2px solid var(--primary-color);
}

.notifications-button-inactive {
    border-bottom: 2px solid var(--muted);
} */

.notification-icon {
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}