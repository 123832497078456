:root {
  --primary-color: #E32728;
  --primary-color-dark: #090d14;
  --success-color: #0c88fc;
  --bg-light: #f5f5f5;
  --bg-secondary: #ebebeb;
  --bg-white: #fdfdfd;
  --text-dark: #181818;
  --text-muted: #787879;
  --text-error: #D90001;
  --muted: #C5C7CF;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-secondary);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
