.main-container {
  width: 100%;
  min-height: calc(100vh - 5rem);
  background-color: var(--bg-white);
  padding-block: 1.5rem;
}

.initial-config-container {
  min-height: 85vh;
  background-color: #ededed;
  margin-right: 3.563rem;
  margin-left: 3.563rem;
  margin-bottom: 3.125rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  position: relative;
}

.initial-config-steps-container {
  background-color: var(--bg-white);
  height: 100%;
  min-height: 79vh;
  border-radius: 10px 0 0 10px;
}

.initial-config-step-card {
  padding-inline: 60px;
  padding-top: 24px;
  padding-bottom: 56px;
}

.initial-config-step-card .MuiCardContent-root {
  padding: 0;
}

.header-white {
  background-color: #ffffff;
}

.card-container {
  min-height: 80vh;
  background-color: var(--bg-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-m-t {
  margin-top: 6.25rem;
}

.card-m-b {
  margin-bottom: 2.25rem;
}

.override-margin-bottom {
  margin-bottom: -50px !important;
  padding-inline: 0 !important;
  min-height: 90vh !important;
  border-radius: 10px 10px 0px 0px !important;
  padding-block: 1.5rem !important;
}

.config-success-background {
  height: 100%;
  width: 100%;
  background: url(../images/confetti.gif);
  background-size: contain;
  opacity: 0.2;
  position: absolute;
}

.MuiStepLabel-iconContainer svg {
  font-size: 2rem;
  transform: translateX(-4px);
}

.MuiStepConnector-root.MuiStepConnector-vertical:is(.Mui-active, .Mui-completed)
  span {
  border-color: var(--primary-color);
}

.MuiStepIcon-text {
  font-size: 0.813rem;
}

.login-logo {
  max-width: 11.25rem;
  height: auto;
  margin-block: 3.75rem;
}

.logo-sm {
  max-width: 7.688rem;
  height: auto;
}

.logo-md {
  max-width: 12.126rem;
  height: auto;
}

.logo-lg {
  width: 14.626rem;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .main-container {
    width: 100%;
    min-height: 79vh;
    margin-right: 3.563rem;
    margin-bottom: 3.125rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem;
    padding-block: 2.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .logo-sm {
    width: 5.375rem;
  }
}
