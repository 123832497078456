.refund-packages-table .select-quantity-disable-open {
  pointer-events: none;
}
.refund-packages-table .MuiSelect-select {
  padding-inline: 23px;
}
.refund-packages-table .select-quantity-disable-open .MuiSvgIcon-root {
  display: none;
}

.refund-product-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}

.fixed-column {
  width: 165px;
}

.fixed-address-column {
  width: 200px;
}

@media screen and (min-width: 1024px) {
  .carriers-table tbody tr,
  .carriers-table thead tr {
    display: grid;
    grid-template-columns: 11% 9% 30% 8% 16% 13% 13%;
  }

  .carriers-table-merchant tbody tr,
  .carriers-table-merchant thead tr {
    display: grid;
    grid-template-columns: 15% 15% 40% 20% 10%;
  }

  .carries-table-merchant-sm tbody tr,
  .carries-table-merchant-sm thead tr {
    display: grid;
    grid-template-columns: 20% 15% 55% 10%;
  }

}
